import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MonoguseApp extends Vue {
  protected monogusaBranches = ["CAI-26M72", "CAI-92M51", "CAI-49M78"]
  protected monogusaLink = 'https://app.monoxer.com/tasks'

  protected get branchCode() {
    return Vue.prototype.$cookies.get('dataGdls').branchCode
  }
}