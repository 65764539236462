




























































import { Component, Vue, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import StudentBottomPanel from '@/components/organisms/v2/StudentBottomPanel.vue'
import DrillWebApi from '@/mixins/drillsv2/WebApi'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import MonoguseApp from '@/mixins/monoguse/MonoguseApp'

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
    LabelBase,
    StudentBottomPanel,
  },
})
export default class CurriculumSUnitsClassCategory extends Mixins(DrillWebApi, DrillTransition, MonoguseApp) {
  private subjectCode = this.$route.params.subjectCode
  private curriculumSUnitId = Number(this.$route.params.curriculumSUnitId)

  // FIXME: GDLS-1923 AI学習モード限定になっているので、必要になった場合は修正
  // AI学習関連の定義値
  private studyLogicType = 'AI'
  private classModeCode = 'AI'

  private curriculumSUnitName = ''
  private classPolicy: { [key: string]: boolean } = {}
  private history: { [key: string]: any } = {}

  // 小項目に対して学習内容が実施可能かを保持する
  private actionEnabled: { [key: string]: boolean } = {}

  public async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.loadDatas()
    Vue.prototype.$loading.complete()
  }

  private async loadDatas(): Promise<void> {
    const isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
    if (isV3) {
      await Vue.prototype.$http.httpWithToken
        .get('/curriculum_s_unit_categories', {
          params: {
            curriculumSUnitId: Number(this.$route.params.curriculumSUnitId),
            classMode: 'AI',
            subjectCode: this.subjectCode,
          },
        })
        .then((res: any) => {
          this.curriculumSUnitName = res.data.curriculumSUnitName
          this.classPolicy = res.data.classPolicy
        })
      await Vue.prototype.$http.httpWithToken
        .get('/v2/curriculumSUnitCategories', {
          params: {
            curriculumSUnitId: this.curriculumSUnitId,
            subjectCode: this.subjectCode,
          },
        })
        .then((res: any) => {
          this.history = res.data.history
          this.actionEnabled = res.data.actionEnabled
        })
    } else {
      await Vue.prototype.$http.httpWithToken
        .get('/v2/curriculumSUnitCategories', {
          params: {
            curriculumSUnitId: this.curriculumSUnitId,
            subjectCode: this.subjectCode,
          },
        })
        .then((res: any) => {
          this.curriculumSUnitName = res.data.curriculumSUnitName
          this.classPolicy = res.data.classPolicy
          this.history = res.data.history
          this.actionEnabled = res.data.actionEnabled
        })
    }
  }

  private get introductionUrl(): string {
    return `/student/v2/curriculum-s-units/${this.curriculumSUnitId}/introduction-video`
  }

  private get unitListUrl(): string {
    return `/student/v2/curriculum-s-units/AI/${this.subjectCode}`
  }

  private get rectangleStyleSet(): { [key: string]: string } {
    let listSize = 0
    if (this.classPolicy['EZ']) listSize += 1
    if (this.classPolicy['RK']) listSize += 1
    if (this.classPolicy['EN']) listSize += 1
    let height = '0'
    if (listSize === 3) {
      height = '180px'
    } else if (listSize === 2) {
      height = '90px'
    }
    return {
      '--rectangle-height': height,
    }
  }

  /**
   * 演習開始
   */
  private async startDrill(classCategoryCode: string) {
    Vue.prototype.$loading.start()

    if (classCategoryCode == 'RK') {
      this.$store.commit('drills/setIsClassCategoryCodeRK', true)
    } else {
      this.$store.commit('drills/setIsClassCategoryCodeRK', false)
    }
    // ドリル開始APIを実行
    const drill = await this.startDrillApi(this.studyLogicType, this.classModeCode, classCategoryCode, {
      curriculumSUnitId: this.curriculumSUnitId,
    })

    Vue.prototype.$loading.complete()
    // 演習画面へ遷移
    this.$router.push({
      path: this.urlDrillWithId(drill.resultDrillId, drill.currentPage),
    })
  }

  /**
   * 解説動画画面に遷移する
   */
  public transferIntroductionVideo() {
    this.$router.push({
      path: this.introductionUrl,
      query: {
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
      },
    })
  }
}
