/**
 * ドリル関連の自動遷移処理
 */
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DrillTransition extends Vue {
  /**
   * 授業モードごとの初期画面(一覧画面)へ遷移
   *  エラー時などに呼び出す
   *
   * @param classMode 授業モード
   */
  protected jumpDefault(classMode: string) {
    this.$router.push({
      path: this.urlCurriculumList(classMode),
    })
  }

  /**
   * 授業モードごとの一覧画面URL
   *
   * @param classMode 授業モード
   */
  protected urlCurriculumList(classMode: string): string {
    switch (classMode) {
      case 'MN':
        // 間違い直し
        return `/student/v2/curriculum-s-units/${classMode}/${this.currentSubjectCode}`
      case 'AI':
        // AI学習
        if (this.currentCurriculumSUnitId) {
          // 小項目IDが確定している場合はその項目の一覧へ
          return `/student/v2/curriculum-s-units/${this.currentSubjectCode}/${this.currentCurriculumSUnitId}/class-categories`
        }
        // 小項目IDが不明な場合は項目選択へ
        return `/student/v2/curriculum-s-units/${classMode}/${this.currentSubjectCode}`
      default:
        // 例外ケース
        return '/student/dashboard'
    }
  }

  /**
   * 演習画面への遷移URL
   */
  protected get urlDrill(): string {
    return this.urlDrillWithId(this.resultDrillId)
  }

  /**
   * 演習画面への遷移URL(resultDrillId指定)
   */
  protected urlDrillWithId(resultDrillId: number, page?: number): string {
    return `/student/v2/drill/?resultDrillId=${resultDrillId}&page=${page || this.currentPage + 1 || 1}`
  }

  /**
   * 正誤入力画面への遷移URL
   */
  protected get urlScoring(): string {
    return `/student/v2/drill/scoring/?resultDrillId=${this.resultDrillId}&page=${this.currentPage}`
  }

  /**
   * 問題結果画面への遷移URL
   */
  protected get urlResultUnit(): string {
    return `/student/v2/drill/result/unit/?resultDrillId=${this.resultDrillId}&page=${this.currentPage}`
  }

  /**
   * 全結果画面への遷移URL
   */
  protected get urlResultAll(): string {
    return `/student/v2/drill/result/?resultDrillId=${this.resultDrillId}`
  }

  /**
   * クエリのresultDrillIdを取得する
   *
   * @returns resultDrillId
   */
  protected get resultDrillId(): number {
    return Number(this.$route.query.resultDrillId)
  }

  /**
   * ストアから現在ページを取得する
   *
   * @returns 現在のページ番号
   */
  private get currentPage(): number {
    return Number(this.$store.getters['drillsV2/currentPage'])
  }

  /**
   * ストアから教科コードを取得する
   *
   * @returns 教科コード
   */
  private get currentSubjectCode(): string {
    return this.$store.getters['drillsV2/subjectCode']
  }

  /**
   * ストアから学習小項目を取得する
   *
   * @returns 小項目ID
   */
  private get currentCurriculumSUnitId(): number | null {
    const setCurriculumSUnitIds = this.$store.getters['drillsV2/curriculumSUnitIds']
    // nullブロック
    if (!Array.isArray(setCurriculumSUnitIds)) {
      return null
    }
    if (setCurriculumSUnitIds.length <= 0) {
      return null
    }

    // 先頭要素を返す
    return setCurriculumSUnitIds[0]
  }

  /**
   * ストアから現在のページタイプを取得する
   *
   * @returns ページタイプ
   */
  protected get pageType(): string {
    return this.$store.getters['drillsV2/pageType']
  }
}
